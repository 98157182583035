<template>
  <div class="col-12 p-0 my-3 my-md-5">
    <div class="container-lg" >
      <div class="row mx-0 justify-content-center">
        <div class="col-md-12">
          <PageTitle title="DONATION HISTORY" />
        </div>
        <div class="col-9 col-md-12 text-center title green-text my-4" ref="thedonationsSection">
          View all your donations
        </div>
        <div class="col-10 col-md-5 text-center green-text my-md-4">
          <Search type="text" color="outline" size="big" placeholder="search my donations..." align="left" id="search" v-model="transactionSearch"/>
        </div>
      </div>
      <div class="w-100 my-3 my-md-5" ref="thedonations">
        <PastDonations :transactionSearch="transactionSearch" :theFilter="theFilter" @goToDonations="goToDonations" :isLoading="(userGroupTransactionsStatus && userGroupTransactionsStatus !== 'ready') || (organisationTransactionsStatus && organisationTransactionsStatus !== 'ready') || userTransactionsStatus !== 'ready'" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    PageTitle: defineAsyncComponent(() => import('@/components/PageTitle.vue')),
    Search: defineAsyncComponent(() => import('@/components/Search.vue')),
    PastDonations: defineAsyncComponent(() => import('./PastDonations.vue'))
  },
  name: 'ProfileDonationsSection',
  props: ['scrollTo'],
  data () {
    return {
      transactionSearch: '',
      theFilter: null
    }
  },
  async mounted () {
    const id = parseInt(this.$route.params.id)
    if (id === 2) {
      await this.fetchGroups()
      this.getGroups()
      await this.fetchOrganisations()
      this.getOrganisations()
      await this.fetchUserTransactions()
    }
    if (id !== 0 && id !== 2) {
      this.goToDonationsSection()
      this.theFilter = id
    }
    if (id !== 2) {
      this.getAllTransactions()
    }
  },
  watch: {
    scrollTo () {
      if (this.scrollTo) {
        this.goToDonationsSection()
      }
    }
  },
  methods: {
    ...mapActions(['fetchOrganisations', 'fetchUserTransactions', 'fetchOrganisationTransactions', 'fetchGroups', 'fetchGroupTransactions']),
    goToDonations () {
      console.log('Inside')
      var element = this.$refs.thedonations
      console.log('element', element)
      var top = element.offsetTop
      window.scrollTo(0, top)
    },
    goToDonationsSection () {
      console.log('Inside')
      var element = this.$refs.thedonationsSection
      console.log('element', element)
      var top = element.offsetTop
      window.scrollTo(0, top)
    },
    getAllTransactions () {
      this.getGroups()
      this.getOrganisations()
      this.getTransactions()
    },
    async getTransactions () {
      if (this.userTransactions && this.userTransactions.length < 1 && this.userTransactionsStatus !== 'loading') {
        await this.fetchUserTransactions()
      }
    },
    async getGroups () {
      if (!this.groups && this.groupsStatus !== 'loading') {
        await this.fetchGroups()
      }
      if (this.groups && this.groupsTransactions && this.groupsTransactions.length < 1 && this.groupsTransactionsStatus !== 'loading') {
        for (const group of this.groups) {
          await this.fetchGroupTransactions(group.donorGroupID)
        }
      }
    },
    async getOrganisations () {
      if (!this.organisations && this.organisationsStatus !== 'loading') {
        await this.fetchOrganisations()
      }
      if (this.organisations && this.organisationTransactions && this.organisationTransactions.length < 1 && this.organisationTransactionsStatus !== 'loading') {
        for (const organisation of this.organisations) {
          await this.fetchOrganisationTransactions(organisation.organisationID)
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'transactions',
      'userTransactions',
      'organisationTransactions',
      'userGroupTransactions',
      'organisations',
      'groups',
      'groupsStatus',
      'organisationsStatus',
      'userTransactionsStatus',
      'organisationTransactionsStatus',
      'userGroupTransactionsStatus'
    ])
  }
}
</script>

<style scoped>
.title {
  font-family: "quicksand_bold", Sans-serif;
  font-size: 26px;
  font-weight: 800;
}

@media (min-width: 992px) {
  .title {
    font-size: 43px;
  }
}
</style>
